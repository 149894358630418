export const subLinks = [
  {
    name: "Arcane Creatures",
    path: "/arcane-creatures",
  },
  {
    name: "OG CM",
    path: "/royalties/",
  }
]

export const navLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Mint",
    path: "https://cmacmint.netlify.app/",
  },
  {
    name: "Royalties",
    path: "#",
    children: subLinks,
  },
  {
    name: "NFT viewer",
    path: "/nftviewer",
  },
];

export const socialLinks = [
  {
    name: "Discord",
    path: "https://discord.gg/ZtBPhPKXYc"
  },
  {
    name: "Twitter",
    path: "https://twitter.com/CrazzzyMonsters"
  },
]