// Testnet Cronos for local test
// export const ContractAddress = '0xf71d138Fc195f40785EEEAf1259C3A0eb48C6549'

// export const defaultChainInfo = {
//   chainId: 338,
//   chainName: 'Cronos Testnet',
//   rpcUrls: ['https://evm-t3.cronos.org'],
//   iconUrls: ['https://cronoscan.com/images/brandassets/logo.jpg?v=22.12.2.0'],
//   blockExplorerUrls: ['https://cronos.crypto.org/explorer/testnet3/'],
//   nativeCurrency: {
//    name: 'Cronos',
//    symbol: 'TCRO',
//    decimals: 18,
//   }
// }

// Mainnet Cronos for live page
export const ContractAddress = "0x8f2836874DC85B81C2CF0421aF593E6E8d5DffA1";
export const NewNFTContractAddress =
  "0xc73e9b57f8678c1dd20879fc19369bbc15c62df3";
export const RoyaltyContractAddressCRO =
  "0x1732008B974e970D094dc9b2810D2230Be3c90Bf";
export const RoyaltyContractAddressCRY =
  "0x1f1b82B98Ea65bea0dd39811b80FdBDa9e2ABd84";

export const defaultChainInfo = {
  chainId: "0x19",
  chainName: "Cronos Mainnet",
  rpcUrls: ["https://evm.cronos.org/"],
  iconUrls: ["https://cronoscan.com/images/brandassets/logo.jpg?v=22.12.2.0"],
  blockExplorerUrls: ["https://cronoscan.com/"],
  nativeCurrency: {
    name: "Cronos",
    symbol: "CRO",
    decimals: 18,
  },
};
